import { colorFromPalette } from './colorPalettes'

export const themeColors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  red: colorFromPalette('redVivid'),
  redVivid: colorFromPalette('redVivid'),
  orange: colorFromPalette('orange'),
  orangeVivid: colorFromPalette('orangeVivid'),
  yellow: colorFromPalette('yellow'),
  yellowVivid: colorFromPalette('yellowVivid'),
  limeGreen: colorFromPalette('limeGreen'),
  limeGreenVivid: colorFromPalette('limeGreenVivid'),
  green: colorFromPalette('green'),
  greenVivid: colorFromPalette('greenVivid'),
  teal: colorFromPalette('teal'),
  tealVivid: colorFromPalette('tealVivid'),
  cyan: colorFromPalette('cyan'),
  cyanVivid: colorFromPalette('cyanVivid'),
  lightBlue: colorFromPalette('lightBlue'),
  lightBlueVivid: colorFromPalette('lightBlueVivid'),
  blue: colorFromPalette('blue'),
  blueVivid: colorFromPalette('blueVivid'),
  indigo: colorFromPalette('indigo'),
  indigoVivid: colorFromPalette('indigoVivid'),
  purple: colorFromPalette('purple'),
  purpleVivid: colorFromPalette('purpleVivid'),
  magenta: colorFromPalette('magenta'),
  magentaVivid: colorFromPalette('magentaVivid'),
  pink: colorFromPalette('pink'),
  pinkVivid: colorFromPalette('pinkVivid'),
  blueGrey: colorFromPalette('blueGrey'),
  coolGrey: colorFromPalette('coolGrey'),
  blueGray: colorFromPalette('blueGrey'),
  coolGray: colorFromPalette('coolGrey'),
  grey: colorFromPalette('grey'),
  warmGrey: colorFromPalette('warmGrey'),
  gray: colorFromPalette('grey'),
  warmGray: colorFromPalette('warmGrey'),
}
