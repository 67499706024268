import { colorSwatches } from './colorSwatches'
import { ColorPalettes, ColorShade } from './types'

export const colorPalettes: ColorPalettes = {
  red: {
    50: colorSwatches['red-050'],
    100: colorSwatches['red-100'],
    200: colorSwatches['red-200'],
    300: colorSwatches['red-300'],
    400: colorSwatches['red-400'],
    500: colorSwatches['red-500'],
    600: colorSwatches['red-600'],
    700: colorSwatches['red-700'],
    800: colorSwatches['red-800'],
    900: colorSwatches['red-900'],
  },
  redVivid: {
    50: colorSwatches['red-vivid-050'],
    100: colorSwatches['red-vivid-100'],
    200: colorSwatches['red-vivid-200'],
    300: colorSwatches['red-vivid-300'],
    400: colorSwatches['red-vivid-400'],
    500: colorSwatches['red-vivid-500'],
    600: colorSwatches['red-vivid-600'],
    700: colorSwatches['red-vivid-700'],
    800: colorSwatches['red-vivid-800'],
    900: colorSwatches['red-vivid-900'],
  },
  orange: {
    50: colorSwatches['orange-050'],
    100: colorSwatches['orange-100'],
    200: colorSwatches['orange-200'],
    300: colorSwatches['orange-300'],
    400: colorSwatches['orange-400'],
    500: colorSwatches['orange-500'],
    600: colorSwatches['orange-600'],
    700: colorSwatches['orange-700'],
    800: colorSwatches['orange-800'],
    900: colorSwatches['orange-900'],
  },
  orangeVivid: {
    50: colorSwatches['orange-vivid-050'],
    100: colorSwatches['orange-vivid-100'],
    200: colorSwatches['orange-vivid-200'],
    300: colorSwatches['orange-vivid-300'],
    400: colorSwatches['orange-vivid-400'],
    500: colorSwatches['orange-vivid-500'],
    600: colorSwatches['orange-vivid-600'],
    700: colorSwatches['orange-vivid-700'],
    800: colorSwatches['orange-vivid-800'],
    900: colorSwatches['orange-vivid-900'],
  },
  yellow: {
    50: colorSwatches['yellow-050'],
    100: colorSwatches['yellow-100'],
    200: colorSwatches['yellow-200'],
    300: colorSwatches['yellow-300'],
    400: colorSwatches['yellow-400'],
    500: colorSwatches['yellow-500'],
    600: colorSwatches['yellow-600'],
    700: colorSwatches['yellow-700'],
    800: colorSwatches['yellow-800'],
    900: colorSwatches['yellow-900'],
  },
  yellowVivid: {
    50: colorSwatches['yellow-vivid-050'],
    100: colorSwatches['yellow-vivid-100'],
    200: colorSwatches['yellow-vivid-200'],
    300: colorSwatches['yellow-vivid-300'],
    400: colorSwatches['yellow-vivid-400'],
    500: colorSwatches['yellow-vivid-500'],
    600: colorSwatches['yellow-vivid-600'],
    700: colorSwatches['yellow-vivid-700'],
    800: colorSwatches['yellow-vivid-800'],
    900: colorSwatches['yellow-vivid-900'],
  },
  limeGreen: {
    50: colorSwatches['lime-green-050'],
    100: colorSwatches['lime-green-100'],
    200: colorSwatches['lime-green-200'],
    300: colorSwatches['lime-green-300'],
    400: colorSwatches['lime-green-400'],
    500: colorSwatches['lime-green-500'],
    600: colorSwatches['lime-green-600'],
    700: colorSwatches['lime-green-700'],
    800: colorSwatches['lime-green-800'],
    900: colorSwatches['lime-green-900'],
  },
  limeGreenVivid: {
    50: colorSwatches['lime-green-vivid-050'],
    100: colorSwatches['lime-green-vivid-100'],
    200: colorSwatches['lime-green-vivid-200'],
    300: colorSwatches['lime-green-vivid-300'],
    400: colorSwatches['lime-green-vivid-400'],
    500: colorSwatches['lime-green-vivid-500'],
    600: colorSwatches['lime-green-vivid-600'],
    700: colorSwatches['lime-green-vivid-700'],
    800: colorSwatches['lime-green-vivid-800'],
    900: colorSwatches['lime-green-vivid-900'],
  },
  green: {
    50: colorSwatches['green-050'],
    100: colorSwatches['green-100'],
    200: colorSwatches['green-200'],
    300: colorSwatches['green-300'],
    400: colorSwatches['green-400'],
    500: colorSwatches['green-500'],
    600: colorSwatches['green-600'],
    700: colorSwatches['green-700'],
    800: colorSwatches['green-800'],
    900: colorSwatches['green-900'],
  },
  greenVivid: {
    50: colorSwatches['green-vivid-050'],
    100: colorSwatches['green-vivid-100'],
    200: colorSwatches['green-vivid-200'],
    300: colorSwatches['green-vivid-300'],
    400: colorSwatches['green-vivid-400'],
    500: colorSwatches['green-vivid-500'],
    600: colorSwatches['green-vivid-600'],
    700: colorSwatches['green-vivid-700'],
    800: colorSwatches['green-vivid-800'],
    900: colorSwatches['green-vivid-900'],
  },
  teal: {
    50: colorSwatches['teal-050'],
    100: colorSwatches['teal-100'],
    200: colorSwatches['teal-200'],
    300: colorSwatches['teal-300'],
    400: colorSwatches['teal-400'],
    500: colorSwatches['teal-500'],
    600: colorSwatches['teal-600'],
    700: colorSwatches['teal-700'],
    800: colorSwatches['teal-800'],
    900: colorSwatches['teal-900'],
  },
  tealVivid: {
    50: colorSwatches['teal-vivid-050'],
    100: colorSwatches['teal-vivid-100'],
    200: colorSwatches['teal-vivid-200'],
    300: colorSwatches['teal-vivid-300'],
    400: colorSwatches['teal-vivid-400'],
    500: colorSwatches['teal-vivid-500'],
    600: colorSwatches['teal-vivid-600'],
    700: colorSwatches['teal-vivid-700'],
    800: colorSwatches['teal-vivid-800'],
    900: colorSwatches['teal-vivid-900'],
  },
  cyan: {
    50: colorSwatches['cyan-050'],
    100: colorSwatches['cyan-100'],
    200: colorSwatches['cyan-200'],
    300: colorSwatches['cyan-300'],
    400: colorSwatches['cyan-400'],
    500: colorSwatches['cyan-500'],
    600: colorSwatches['cyan-600'],
    700: colorSwatches['cyan-700'],
    800: colorSwatches['cyan-800'],
    900: colorSwatches['cyan-900'],
  },
  cyanVivid: {
    50: colorSwatches['cyan-vivid-050'],
    100: colorSwatches['cyan-vivid-100'],
    200: colorSwatches['cyan-vivid-200'],
    300: colorSwatches['cyan-vivid-300'],
    400: colorSwatches['cyan-vivid-400'],
    500: colorSwatches['cyan-vivid-500'],
    600: colorSwatches['cyan-vivid-600'],
    700: colorSwatches['cyan-vivid-700'],
    800: colorSwatches['cyan-vivid-800'],
    900: colorSwatches['cyan-vivid-900'],
  },
  lightBlue: {
    50: colorSwatches['light-blue-050'],
    100: colorSwatches['light-blue-100'],
    200: colorSwatches['light-blue-200'],
    300: colorSwatches['light-blue-300'],
    400: colorSwatches['light-blue-400'],
    500: colorSwatches['light-blue-500'],
    600: colorSwatches['light-blue-600'],
    700: colorSwatches['light-blue-700'],
    800: colorSwatches['light-blue-800'],
    900: colorSwatches['light-blue-900'],
  },
  lightBlueVivid: {
    50: colorSwatches['light-blue-vivid-050'],
    100: colorSwatches['light-blue-vivid-100'],
    200: colorSwatches['light-blue-vivid-200'],
    300: colorSwatches['light-blue-vivid-300'],
    400: colorSwatches['light-blue-vivid-400'],
    500: colorSwatches['light-blue-vivid-500'],
    600: colorSwatches['light-blue-vivid-600'],
    700: colorSwatches['light-blue-vivid-700'],
    800: colorSwatches['light-blue-vivid-800'],
    900: colorSwatches['light-blue-vivid-900'],
  },
  blue: {
    50: colorSwatches['blue-050'],
    100: colorSwatches['blue-100'],
    200: colorSwatches['blue-200'],
    300: colorSwatches['blue-300'],
    400: colorSwatches['blue-400'],
    500: colorSwatches['blue-500'],
    600: colorSwatches['blue-600'],
    700: colorSwatches['blue-700'],
    800: colorSwatches['blue-800'],
    900: colorSwatches['blue-900'],
  },
  blueVivid: {
    50: colorSwatches['blue-vivid-050'],
    100: colorSwatches['blue-vivid-100'],
    200: colorSwatches['blue-vivid-200'],
    300: colorSwatches['blue-vivid-300'],
    400: colorSwatches['blue-vivid-400'],
    500: colorSwatches['blue-vivid-500'],
    600: colorSwatches['blue-vivid-600'],
    700: colorSwatches['blue-vivid-700'],
    800: colorSwatches['blue-vivid-800'],
    900: colorSwatches['blue-vivid-900'],
  },
  indigo: {
    50: colorSwatches['indigo-050'],
    100: colorSwatches['indigo-100'],
    200: colorSwatches['indigo-200'],
    300: colorSwatches['indigo-300'],
    400: colorSwatches['indigo-400'],
    500: colorSwatches['indigo-500'],
    600: colorSwatches['indigo-600'],
    700: colorSwatches['indigo-700'],
    800: colorSwatches['indigo-800'],
    900: colorSwatches['indigo-900'],
  },
  indigoVivid: {
    50: colorSwatches['indigo-vivid-050'],
    100: colorSwatches['indigo-vivid-100'],
    200: colorSwatches['indigo-vivid-200'],
    300: colorSwatches['indigo-vivid-300'],
    400: colorSwatches['indigo-vivid-400'],
    500: colorSwatches['indigo-vivid-500'],
    600: colorSwatches['indigo-vivid-600'],
    700: colorSwatches['indigo-vivid-700'],
    800: colorSwatches['indigo-vivid-800'],
    900: colorSwatches['indigo-vivid-900'],
  },
  purple: {
    50: colorSwatches['purple-050'],
    100: colorSwatches['purple-100'],
    200: colorSwatches['purple-200'],
    300: colorSwatches['purple-300'],
    400: colorSwatches['purple-400'],
    500: colorSwatches['purple-500'],
    600: colorSwatches['purple-600'],
    700: colorSwatches['purple-700'],
    800: colorSwatches['purple-800'],
    900: colorSwatches['purple-900'],
  },
  purpleVivid: {
    50: colorSwatches['purple-vivid-050'],
    100: colorSwatches['purple-vivid-100'],
    200: colorSwatches['purple-vivid-200'],
    300: colorSwatches['purple-vivid-300'],
    400: colorSwatches['purple-vivid-400'],
    500: colorSwatches['purple-vivid-500'],
    600: colorSwatches['purple-vivid-600'],
    700: colorSwatches['purple-vivid-700'],
    800: colorSwatches['purple-vivid-800'],
    900: colorSwatches['purple-vivid-900'],
  },
  magenta: {
    50: colorSwatches['magenta-050'],
    100: colorSwatches['magenta-100'],
    200: colorSwatches['magenta-200'],
    300: colorSwatches['magenta-300'],
    400: colorSwatches['magenta-400'],
    500: colorSwatches['magenta-500'],
    600: colorSwatches['magenta-600'],
    700: colorSwatches['magenta-700'],
    800: colorSwatches['magenta-800'],
    900: colorSwatches['magenta-900'],
  },
  magentaVivid: {
    50: colorSwatches['magenta-vivid-050'],
    100: colorSwatches['magenta-vivid-100'],
    200: colorSwatches['magenta-vivid-200'],
    300: colorSwatches['magenta-vivid-300'],
    400: colorSwatches['magenta-vivid-400'],
    500: colorSwatches['magenta-vivid-500'],
    600: colorSwatches['magenta-vivid-600'],
    700: colorSwatches['magenta-vivid-700'],
    800: colorSwatches['magenta-vivid-800'],
    900: colorSwatches['magenta-vivid-900'],
  },
  pink: {
    50: colorSwatches['pink-050'],
    100: colorSwatches['pink-100'],
    200: colorSwatches['pink-200'],
    300: colorSwatches['pink-300'],
    400: colorSwatches['pink-400'],
    500: colorSwatches['pink-500'],
    600: colorSwatches['pink-600'],
    700: colorSwatches['pink-700'],
    800: colorSwatches['pink-800'],
    900: colorSwatches['pink-900'],
  },
  pinkVivid: {
    50: colorSwatches['pink-vivid-050'],
    100: colorSwatches['pink-vivid-100'],
    200: colorSwatches['pink-vivid-200'],
    300: colorSwatches['pink-vivid-300'],
    400: colorSwatches['pink-vivid-400'],
    500: colorSwatches['pink-vivid-500'],
    600: colorSwatches['pink-vivid-600'],
    700: colorSwatches['pink-vivid-700'],
    800: colorSwatches['pink-vivid-800'],
    900: colorSwatches['pink-vivid-900'],
  },
  blueGrey: {
    50: colorSwatches['blue-grey-050'],
    100: colorSwatches['blue-grey-100'],
    200: colorSwatches['blue-grey-200'],
    300: colorSwatches['blue-grey-300'],
    400: colorSwatches['blue-grey-400'],
    500: colorSwatches['blue-grey-500'],
    600: colorSwatches['blue-grey-600'],
    700: colorSwatches['blue-grey-700'],
    800: colorSwatches['blue-grey-800'],
    900: colorSwatches['blue-grey-900'],
  },
  coolGrey: {
    50: colorSwatches['cool-grey-050'],
    100: colorSwatches['cool-grey-100'],
    200: colorSwatches['cool-grey-200'],
    300: colorSwatches['cool-grey-300'],
    400: colorSwatches['cool-grey-400'],
    500: colorSwatches['cool-grey-500'],
    600: colorSwatches['cool-grey-600'],
    700: colorSwatches['cool-grey-700'],
    800: colorSwatches['cool-grey-800'],
    900: colorSwatches['cool-grey-900'],
  },
  grey: {
    50: colorSwatches['grey-050'],
    100: colorSwatches['grey-100'],
    200: colorSwatches['grey-200'],
    300: colorSwatches['grey-300'],
    400: colorSwatches['grey-400'],
    500: colorSwatches['grey-500'],
    600: colorSwatches['grey-600'],
    700: colorSwatches['grey-700'],
    800: colorSwatches['grey-800'],
    900: colorSwatches['grey-900'],
  },
  warmGrey: {
    50: colorSwatches['warm-grey-050'],
    100: colorSwatches['warm-grey-100'],
    200: colorSwatches['warm-grey-200'],
    300: colorSwatches['warm-grey-300'],
    400: colorSwatches['warm-grey-400'],
    500: colorSwatches['warm-grey-500'],
    600: colorSwatches['warm-grey-600'],
    700: colorSwatches['warm-grey-700'],
    800: colorSwatches['warm-grey-800'],
    900: colorSwatches['warm-grey-900'],
  },
}

export function colorFromPalette(baseColor: keyof ColorPalettes, defaultColor: keyof ColorShade = 500): ColorShade {
  return {
    ...colorPalettes[baseColor],
    DEFAULT: colorPalettes[baseColor][defaultColor],
  }
}
