import { themeQuartz } from 'ag-grid-community'
import { themeColors } from './colors'

export const agGridTheme = themeQuartz
  .withParams(
    {
      backgroundColor: themeColors.blueGrey[100],
      foregroundColor: themeColors.purple[900],
      headerBackgroundColor: themeColors.purple[700],
      headerTextColor: themeColors.white,
      oddRowBackgroundColor: themeColors.blueGrey[200],
      headerColumnResizeHandleColor: themeColors.yellowVivid[600],
    },
    'light-mpt',
  )
  .withParams(
    {
      backgroundColor: themeColors.blueGrey[100],
      foregroundColor: themeColors.purple[900],
      headerBackgroundColor: themeColors.purple[700],
      headerTextColor: themeColors.white,
      oddRowBackgroundColor: themeColors.blueGrey[200],
      headerColumnResizeHandleColor: themeColors.yellowVivid[600],
    },
    'dark-mpt',
  )
